import { ref } from 'vue';

export const useSlider = () => {
  const slidercontenedor = ref<HTMLDivElement | null>(null);
  const isPaused = ref<boolean>(false);
  let animationFrameId: number | null = null;

  const scroll = (direction: 'next' | 'prev') => {
    if (!slidercontenedor.value) return;

    const scrollAmount = direction === 'next' ? 400 : -400;
    const start = slidercontenedor.value.scrollLeft;
    const startTime = performance.now();

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / 600, 1); // 300ms duration
      const easeProgress = easeInOutCubic(progress);

      slidercontenedor.value!.scrollLeft = start + scrollAmount * easeProgress;

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(animateScroll);
      } else {
        handleScrollEnd();
      }
    };

    cancelAnimationFrame(animationFrameId!);
    animationFrameId = requestAnimationFrame(animateScroll);
  };

  const nextPage = () => scroll('next');
  const previousPage = () => scroll('prev');

  const handleScrollEnd = () => {
    if (!slidercontenedor.value) return;

    const { scrollLeft, scrollWidth, clientWidth } = slidercontenedor.value;

    if (scrollLeft >= scrollWidth - clientWidth) {
      slidercontenedor.value.scrollLeft = 0;
    } else if (scrollLeft === 0) {
      slidercontenedor.value.scrollLeft = scrollWidth - clientWidth;
    }
  };

  const startAutoScroll = () => {
    if (isPaused.value) return;

    const autoScroll = () => {
      if (!isPaused.value && slidercontenedor.value) {
        slidercontenedor.value.scrollLeft += 1;
        if (slidercontenedor.value.scrollLeft >= slidercontenedor.value.scrollWidth - slidercontenedor.value.clientWidth) {
          slidercontenedor.value.scrollLeft = 0;
        }
      }
      animationFrameId = requestAnimationFrame(autoScroll);
    };

    animationFrameId = requestAnimationFrame(autoScroll);
  };

  const pauseScroll = () => {
    isPaused.value = true;
    cancelAnimationFrame(animationFrameId!);
  };

  const resumeScroll = () => {
    isPaused.value = false;
    startAutoScroll();
  };

  onUnmounted(() => {
    cancelAnimationFrame(animationFrameId!);
  });

  return {
    slidercontenedor,
    nextPage,
    previousPage,
    startAutoScroll,
    pauseScroll,
    resumeScroll,
    isPaused,
  };
};

// Función de easing para un scrolling más suave
function easeInOutCubic(t: number): number {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}